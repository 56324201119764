import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import {
  GAME_SHORT_NAMES,
  GAME_SYMBOL_BY_SHORT_NAME,
} from "@/app/constants.mjs";
import ArticleList from "@/marketing/ArticleList.jsx";
import {
  Section,
  SectionTitle,
} from "@/marketing/ContentfulArticles.style.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function PatchNotes() {
  const { t } = useTranslation();

  const shortName = GAME_SHORT_NAMES[GAME_SYMBOL_BY_SHORT_NAME.MarvelRivals];

  const {
    contentful: { related },
  } = useSnapshot(readState);

  const patchnotes = useMemo(
    () =>
      related
        .filter((i) => !/release\sdate/i.test(i.title))
        .filter((i) =>
          ["marvelRivals", "patchNotes"].every((t) =>
            i.tags.find((j) => j === t),
          ),
        ),
    [related],
  );
  return (
    <Section>
      <header>
        <SectionTitle>
          {t(
            "marvelrivals:title.marvelRivalsPatchNotes",
            "Marvel Rivals Patch Notes",
          )}
        </SectionTitle>
      </header>
      <ArticleList
        articles={patchnotes.map((i) => ({
          id: i.id,
          coverImageUrl: i.imageUrl,
          title: i.title,
          excerpt: i.excerpt,
          createdAt: i.createdAt,
          link: `/${shortName}/article/${i.id}`,
        }))}
        count={9}
      />
    </Section>
  );
}

export function meta() {
  return {
    title: ["marvelrivals:title.patchNotes", "Marvel Rivals Patch Notes"],
    description: [
      "marvelrivals:meta.patchNotes.description",
      "Discover the latest marvel rivals patch notes for detailed updates on new features, gameplay improvements, and bug fixes. Stay ahead in your game with the most recent changes and enhancements for an optimal Marvel Rivals experience.",
    ],
    subtitle: true,
  };
}
